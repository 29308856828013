$(document).ready(
    function () {

        let p = $("body").attr("p");

        if (p == 'organizer_event_customers' || p == 'organizer_event_subevent_customers') {

            let order_by = 'reg_date';
            let order_by_direction = 'asc';

            $(document).on("click", ".sort", function (e) {
                e.preventDefault();
                let page = 1;
                let location_id = $("#location_id").val();
                let status = $("#status :selected").val();
                let start_date = $("#start_date").val();
                let start_time = $("#start_time").val();
                let end_date = $("#end_date").val();
                let end_time = $("#end_time").val();
                order_by = $(this).attr('id');
                order_by_direction = $(this).attr('data-order-direction');
                let location_type = $('input[name=location_type]:checked', '#order_search_form').val();
                let search_string = $("#search_string").val();
                getEventCustomerList(page, location_id, location_type, status, order_by, order_by_direction, start_date, start_time, end_date, end_time, search_string);
            });

            $(document).on("keypress", "#search_string", function (e) {
                if (e.which === 13) {
                    let page = 1;
                    let location_id = $("#location_id").val();
                    let status = $("#status :selected").val();
                    let start_date = $("#start_date").val();
                    let start_time = $("#start_time").val();
                    let end_date = $("#end_date").val();
                    let end_time = $("#end_time").val();
                    let location_type = $('input[name=location_type]:checked', '#order_search_form').val();
                    let search_string = $("#search_string").val();
                    getEventCustomerList(page, location_id, location_type, status, order_by, order_by_direction, start_date, start_time, end_date, end_time, search_string);
                }
            });

            $(document).on("change", ".datepicker-input", function (e) {
                e.preventDefault();
                let page = 1;
                let location_id = $("#location_id").val();
                let status = $("#status :selected").val();
                let start_date = $("#start_date").val();
                let start_time = $("#start_time").val();
                let end_date = $("#end_date").val();
                let end_time = $("#end_time").val();
                let location_type = $('input[name=location_type]:checked', '#order_search_form').val();
                let search_string = $("#search_string").val();
                getEventCustomerList(page, location_id, location_type, status, order_by, order_by_direction, start_date, start_time, end_date, end_time, search_string);
            });

            $(document).on("change", "#collect_orders_on_same_table", function (e) {
                e.preventDefault();
                let page = 1;
                let location_id = $("#location_id").val();
                let status = $("#status :selected").val();
                let start_date = $("#start_date").val();
                let start_time = $("#start_time").val();
                let end_date = $("#end_date").val();
                let end_time = $("#end_time").val();
                if ($(this).is(":checked")) {
                    order_by = 'table_number';
                } else {
                    order_by = 'reg_date';
                }
                order_by_direction = 'asc';
                let location_type = $('input[name=location_type]:checked', '#order_search_form').val();
                let search_string = $("#search_string").val();
                getEventCustomerList(page, location_id, location_type, status, order_by, order_by_direction, start_date, start_time, end_date, end_time, search_string);
            });

            $(document).on("change", ".input_location_type", function (e) {
                e.preventDefault();
                let page = 1;
                let location_id = $("#location_id").val();
                let status = $("#status :selected").val();
                let start_date = $("#start_date").val();
                let start_time = $("#start_time").val();
                let end_date = $("#end_date").val();
                let end_time = $("#end_time").val();
                let location_type = $('input[name=location_type]:checked', '#order_search_form').val();
                let search_string = $("#search_string").val();
                getEventCustomerList(page, location_id, location_type, status, order_by, order_by_direction, start_date, start_time, end_date, end_time, search_string);
            });

            $(document).on("click", ".pagination a", function (e) {
                e.preventDefault();
                let page = parseInt($(this).attr("href").substring(6));
                let location_id = $("#location_id").val();
                let status = $("#status :selected").val();
                let start_date = $("#start_date").val();
                let start_time = $("#start_time").val();
                let end_date = $("#end_date").val();
                let end_time = $("#end_time").val();
                let location_type = $('input[name=location_type]:checked', '#order_search_form').val();
                let search_string = $("#search_string").val();
                getEventCustomerList(page, location_id, location_type, status, order_by, order_by_direction, start_date, start_time, end_date, end_time, search_string);
            });

            $(document).on("change", "#status", function (e) {
                e.preventDefault();
                let page = 1;
                let location_id = $("#location_id").val();
                let status = $("#status :selected").val();
                let start_date = $("#start_date").val();
                let start_time = $("#start_time").val();
                let end_date = $("#end_date").val();
                let end_time = $("#end_time").val();
                if ($("#collect_orders_on_same_table").is(":checked")) {
                    order_by = 'table_number';
                } else {
                    order_by = 'reg_date';
                }
                order_by_direction = 'asc';
                let location_type = $('input[name=location_type]:checked', '#order_search_form').val();
                let search_string = $("#search_string").val();
                getEventCustomerList(page, location_id, location_type, status, order_by, order_by_direction, start_date, start_time, end_date, end_time, search_string);
            });

        }


        function getEventCustomerList(page = 1, location_id, location_type, status = null, order_by = null, order_by_direction = 'asc', start_date, start_time, end_date, end_time, search_string = '') {

            let employer_token = null;
            if ($("body").attr("employer_token") != undefined) {
                employer_token = $("body").attr("employer_token");
            }

            $.ajax({
                type: 'POST',
                url: "/ajax/organizer_event_customers/search",
                data: {
                    page,
                    location_id,
                    location_type,
                    status,
                    order_by,
                    order_by_direction,
                    start_date,
                    start_time,
                    end_date,
                    end_time,
                    search_string,
                    employer_token
                }
            })
                .done(function (data) {

                    if (data.html !== undefined && data.html !== null) {
                        $("#ticket-list").remove();
                        $(".pagination").remove();
                        $("#order-list-search").after(data.html);
                    }

                    $('html,body').scrollTop(0);

                    $('[data-toggle="popover"]').popover();

                })
                .fail(function (data) {

                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }

                })
        }

    }
);

