$(document).ready(
    function () {

        let p = $("body").attr("p");

        if (p == 'organizer_event_customers') {

            let soundNotificationOnNewOrder = document.getElementById("sound-notification-new-order-audio");
            function playSoundNotificationOnNewOrder() {
                soundNotificationOnNewOrder.play();
            }

            setInterval(function () {
                let page = 1;
                if ($("ul.pagination").children('li.active').length) {
                    page = $("ul.pagination").children('li.active').children('span')[0].childNodes[0].nodeValue.trim();
                }
                let location_id = $("#location_id").val();
                let start_date = $("#start_date").val();
                let start_time = $("#start_time").val();
                let end_date = $("#end_date").val();
                let end_time = $("#end_time").val();
                let order_by = $("#order-list-table").children('thead').children('tr').children('th.active').attr('id')
                let order_by_direction = $("#order-list-table").children('thead').children('tr').children('th.active').attr('data-order-direction');
                let start = $("#start_date").attr("data-last-time-checked-for-new-orders");
                let number_of_orders_on_page = $("#order-list-table").children('tbody').children('tr').length;
                let location_type = $('input[name=location_type]:checked', '#order_search_form').val();
                let search_string = $("#search_string").val();
                getNewOrders(page, location_id, location_type, order_by, order_by_direction, start, start_date, start_time, end_date, end_time, number_of_orders_on_page, search_string);
            }, 20000);


            function getNewOrders(page = 1, location_id, location_type, order_by = null, order_by_direction = 'asc', start, start_date, start_time, end_date, end_time, number_of_orders_on_page = 0, search_string = '') {
                let status = $("#status :selected").val();

                let employer_token = null;
                if ($("body").attr("employer_token") != undefined) {
                    employer_token = $("body").attr("employer_token");
                }

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_customers/get_new_orders",
                    data: {
                        page,
                        status: status,
                        location_id,
                        location_type,
                        order_by,
                        order_by_direction,
                        start,
                        start_date,
                        start_time,
                        end_date,
                        end_time,
                        number_of_orders_on_page,
                        search_string,
                        employer_token
                    }
                })
                    .done(function (data) {

                        $('.new-order').each(function () {
                            $(this).removeClass('new-order');
                        });

                        if (data.new_orders !== undefined && data.new_orders !== null) {
                            $("table#order-list-table").children("tbody").append(data.new_orders);
                            $(".new-order").fadeOut(250).fadeIn(250).fadeOut(250).fadeIn(250).fadeOut(250).fadeIn(250);
                        }

                        if (data.order_status_change_since_last_check !== undefined && data.order_status_change_since_last_check !== null) {
                            let ordersChanged = jQuery.parseJSON(data.order_status_change_since_last_check);
                            $.each(ordersChanged, function (key, value) {
                                if (value.status != status) {
                                    $('tr#order-product-order-id-' + value.order_id).fadeOut(1000);
                                    setTimeout(function () {
                                        $('tr#order-product-order-id-' + value.order_id).remove();
                                    }, 1000);
                                }
                                if (value.status == status) {
                                    let orderStatusButtons = $('button.button-order-status-order-id-' + value.order_id);
                                    orderStatusButtons.each(function () {
                                        if ($(this).val() == status && $(this).hasClass('btn-secondary')) {
                                            $(this).removeClass('btn-secondary')
                                        }
                                        if ($(this).val() == status && !$(this).hasClass('btn-primary')) {
                                            $(this).addClass('btn-primary')
                                        }
                                        if ($(this).val() != status && $(this).hasClass('btn-primary')) {
                                            $(this).removeClass('btn-primary')
                                        }
                                        if ($(this).val() != status && !$(this).hasClass('btn-secondary')) {
                                            $(this).addClass('btn-secondary')
                                        }
                                    });
                                }
                            });
                        }

                        if (data.order_product_status_change_since_last_check !== undefined && data.order_product_status_change_since_last_check !== null) {
                            let orderProductsChanged = jQuery.parseJSON(data.order_product_status_change_since_last_check);
                            $.each(orderProductsChanged, function (key, value) {
                                let orderProductStatusButtons = $('button.button-order-product-status-order-product-id-' + value.order_product_id);
                                orderProductStatusButtons.each(function () {
                                    if ($(this).val() == value.status && $(this).hasClass('btn-secondary')) {
                                        $(this).removeClass('btn-secondary')
                                    }
                                    if ($(this).val() == value.status && !$(this).hasClass('btn-primary')) {
                                        $(this).addClass('btn-primary')
                                    }
                                    if ($(this).val() != value.status && $(this).hasClass('btn-primary')) {
                                        $(this).removeClass('btn-primary')
                                    }
                                    if ($(this).val() != value.status && !$(this).hasClass('btn-secondary')) {
                                        $(this).addClass('btn-secondary')
                                    }
                                });

                            });
                        }

                        if (data.order_id_with_order_produces_have_same_order_status !== undefined && data.order_id_with_order_produces_have_same_order_status !== null) {
                            let orderIdsWithOrderProductsSameStatus = jQuery.parseJSON(data.order_id_with_order_produces_have_same_order_status);
                            console.log(orderIdsWithOrderProductsSameStatus);
                            $.each(orderIdsWithOrderProductsSameStatus, function (key, value) {
                                console.log(value.order_id);
                                $('tr#order-product-order-id-' + value.order_id).fadeOut(1000);
                                setTimeout(function () {
                                    $('tr#order-product-order-id-' + value.order_id).remove();
                                }, 1000);
                            });
                        }

                        if (data.pagination !== undefined && data.pagination !== null) {
                            $(".pagination").remove();
                            $("#ticket-list").after(data.pagination);
                        }

                        if (data.time_checked_new_orders !== undefined && data.time_checked_new_orders !== null) {
                            $("#start_date").attr('data-last-time-checked-for-new-orders', data.time_checked_new_orders);
                        }

                        if (data.takeaway_in_next_30_minutes !== undefined && data.takeaway_in_next_30_minutes !== null && data.takeaway_in_next_30_minutes === true && $('#alert-lightbulb-button').hasClass('d-none')) {
                            $('#alert-lightbulb-button').removeClass('d-none');
                        }

                        if (data.sound_notification_new_order !== undefined && data.sound_notification_new_order !== null && data.sound_notification_new_order === true) {
                            playSoundNotificationOnNewOrder();
                        }

                        $('[data-toggle="popover"]').popover();

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })
            }

        }

    }
);

