let orderStorageIds = null;

$(document).ready(
    function () {
        const body = $("body");
        const p = body.attr("p");

        if (p === 'events') {
            const organizerId = $(body).attr('organizer_id');
            const locationId = $('#location').data('location-id');

            $(document).on('click', '#order-storage-modal', function () {
                let products_in_cart = 0;
                if (locationCart.number_of_products !== undefined && locationCart.number_of_products >= 0) {
                    products_in_cart = locationCart.number_of_products;
                }
                $.ajax({
                    type: 'POST',
                    url: '/ajax/order/get_order_storage_modal',
                    data: {
                        organizer_id: organizerId,
                        location_id: locationId,
                        products_in_cart: products_in_cart
                    }
                })
                    .done(function (data) {
                        if (data.html !== undefined && data.html !== null) {
                            if ($('#order-storage-list-modal').length > 0) {
                                $('#order-storage-list-modal').remove();
                            }
                            $('#content-body').append(data.html);
                            $('#order-storage-list-modal').modal('show');
                        }
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    })
            });

            let orderStorageIdentifierFormSubmitAjaxLock = false;
            $(document).on('submit', '#order-storage-identifier-form', function () {
                const identifier = $('#order-storage-identifier-form #order-storage-identifier-new').val();
                if (!orderStorageIdentifierFormSubmitAjaxLock && identifier != '') {
                    orderStorageIdentifierFormSubmitAjaxLock = true;
                    $('#order-storage-list-modal').modal('hide');
                    locationCart.order_storage_identifier = identifier;
                    getCartModal(true);
                    setTimeout(function () {
                        orderStorageIdentifierFormSubmitAjaxLock = false;
                    }, 3000);
                }
                return false;
            });

            let orderStorageIdentifierSelectAjaxLock = false;
            $(document).on('click', '.order-storage-list .order-storage-list-identifier', function () {
                if (!orderStorageIdentifierSelectAjaxLock) {
                    orderStorageIdentifierSelectAjaxLock = true;

                    if (locationCart.number_of_products == 0) {
                        const identifier = $(this).data('identifier');

                        $.ajax({
                            type: 'POST',
                            url: "/ajax/order/get_cart_from_order_storage",
                            data: {
                                organizer_id: organizerId,
                                location_id: locationId,
                                identifier: identifier
                            }
                        })
                            .done(function (data) {
                                if (data.location_id !== undefined && data.location_id !== null && data.location_id == locationId) {
                                    $('#order-storage-list-modal').modal('hide');
                                    setLocationCart(data);
                                }
                            })
                            .fail(function (data) {
                                if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                    document.location.href = data.responseJSON.redirect_url;
                                }
                            })
                            .always(function () {
                                setTimeout(function () {
                                    orderStorageIdentifierSelectAjaxLock = false;
                                }, 3000);
                            })

                    } else {
                        $('#order-storage-list-modal').modal('hide');
                        locationCart.order_storage_identifier = $(this).data('identifier');
                        getCartModal(true);
                        setTimeout(function () {
                            orderStorageIdentifierSelectAjaxLock = false;
                        }, 3000);
                    }
                }
            });


        }
    }
);