$(document).ready(
    function () {
        let p = $("body").attr("p");
        if (p === 'organizer_location_dashboard') {


            function pieCart(container, primalData, title) {
                const tooltipLabelText = (container == 'location-dashboard-pie-vat') ? 'varer' : 'ordrer';
                Highcharts.chart(container, {
                    chart: {
                        type: 'variablepie'
                    },
                    title: false,
                    exporting: {enabled: false},
                    credits: {enabled: false},
                    tooltip: {
                        headerFormat: '',
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
                            'Sum eks. mva.: <b>{point.y},-</b><br/>' +
                            'Antall ' + tooltipLabelText + ': <b>{point.z}</b><br/>'
                    },
                    plotOptions: {
                        variablepie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false,
                            },
                            showInLegend: true
                        }
                    },
                    series: [{
                        minPointSize: 10,
                        innerSize: '20%',
                        zMin: 0,
                        name: 'countries',
                        data: primalData
                    }],
                    legend: {
                        layout: 'vertical',
                        align: 'right',
                        verticalAlign: 'middle',
                        floating: false
                    }
                });
            }

            function barChart(primalData) {
                Highcharts.chart('event-bar-graph', {
                    chart: {type: 'column'},
                    exporting: {enabled: false},
                    title: false,
                    xAxis: {
                        categories: primalData.date,
                        title: {text: $("#admin-dashboard-bar-graph").attr("data-x-axis-text")}
                    },
                    yAxis: {
                        title: {text: $("#admin-dashboard-bar-graph").attr("data-y-axis-text")}
                    },
                    credits: {enabled: false},
                    series: [{
                        showInLegend: false,
                        data: primalData.revenue,
                        name: 'Omsetning ink. mva.'
                    }],
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.y}</b><br/>',
                        valueSuffix: ',-',
                        shared: true
                    },
                });
            }

            function ajaxGetStatisticsData() {
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_location_dashboard/get_statistic",
                    data: {
                        location_id: $("body").attr("event_id")
                    }
                })
                    .done(function (data) {
                        $("#location-dashboard-turnover-sum #turnover-title-period").hide().text(data.period_label).fadeIn(400);
                        $("#location-dashboard-turnover-sum #turnover-sum").hide().html('<span>NOK ink. mva.</span>' + data.turnover_sum_formatted).fadeIn(400);

                        pieCart("location-dashboard-pie-vat", data.pie_vat_data, data.revenue_sum_formatted);
                        pieCart("location-dashboard-pie-payment-option", data.pie_payment_option_data, data.revenue_sum_formatted);
                        barChart(data);
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    })
            }

            ajaxGetStatisticsData();
        }
    }
);
