$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'organizer') {

            $('select[name=city_id]').select2({
                theme: 'bootstrap4',
            });

        }

    }
);