$(document).ready(
    function () {

        addDTimePicker();
        addDatePicker();

        let organizer_settings_ajax_lock = false;

        $(document).on("submit", "#organizer_settings_form", function () {

            if (!organizer_settings_ajax_lock) {

                organizer_settings_ajax_lock = true;

                let formData = new FormData(document.getElementById('organizer_settings_form'));
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_settings/update",
                    data: formData,
                    enctype: 'multipart/form-data',
                    async: true,
                    cache: false,
                    processData: false,
                    contentType: false
                })
                    .done(function (data) {

                        if (data.message !== undefined && data.message !== null) {
                            prependAlert('#div_organizer_settings_button', data.message, 'success', null, '#organizer_settings_form')
                        }
                        setTimeout(function () {
                            document.location.href = "/settings";
                        }, 1500);

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            prependAlert('#div_organizer_settings_button', data.responseJSON.error_message, 'danger', null, '#organizer_settings_form')
                        }

                        organizer_settings_ajax_lock = false;

                    })
                    .always(function () {
                        organizer_settings_ajax_lock = false;
                    });

            }

            return false;

        });


        function addDTimePicker() {
            $('.datepicker-start-time-location-opening-hours').each(function () {
                let $startTime = $(this).pickatime({
                    format: 'HH:i',
                    formatSubmit: 'HH:i',
                    editable: true
                });
                $startTime.on('click', function (ev) {
                    let pickerStartTime = $startTime.pickatime('picker');
                    pickerStartTime.open();
                });
            });
            $('.datepicker-end-time-location-opening-hours').each(function () {
                let $endTime = $(this).pickatime({
                    format: 'HH:i',
                    formatSubmit: 'HH:i',
                    editable: true
                });
                $endTime.on('click', function () {
                    let pickerEndTime = $endTime.pickatime('picker');
                    let pickerTimeId = $(this).attr('aria-owns');
                    let picketTimeLi = $('#' + pickerTimeId + ' li');
                    picketTimeLi.each(function () {
                        if ($(this).attr('data-pick') >= 1440) {
                            $(this).remove();
                        }
                    });
                    $('#' + pickerTimeId).find("ul.picker__list li.picker__list-item:last").after(
                        '<li class="picker__list-item" data-pick="1440" role="option" aria-label="00:00">00:00 (+ 1 dag)</li>' +
                        '<li class="picker__list-item" data-pick="1470" role="option" aria-label="00:30">00:30 (+ 1 dag)</li>' +
                        '<li class="picker__list-item" data-pick="1500" role="option" aria-label="00:00">01:00 (+ 1 dag)</li>' +
                        '<li class="picker__list-item" data-pick="1530" role="option" aria-label="01:30">01:30 (+ 1 dag)</li>' +
                        '<li class="picker__list-item" data-pick="1560" role="option" aria-label="00:00">02:00 (+ 1 dag)</li>' +
                        '<li class="picker__list-item" data-pick="1590" role="option" aria-label="02:30">02:30 (+ 1 dag)</li>' +
                        '<li class="picker__list-item" data-pick="1620" role="option" aria-label="00:00">03:00 (+ 1 dag)</li>' +
                        '<li class="picker__list-item" data-pick="1650" role="option" aria-label="03:30">03:30 (+ 1 dag)</li>' +
                        '<li class="picker__list-item" data-pick="1680" role="option" aria-label="00:00">04:00 (+ 1 dag)</li>'
                    );
                    pickerEndTime.open();
                });
            });
        }

        function addDatePicker() {
            let date = new Date();
            let $startDateOpeningHours = $(".datepicker-date-location-opening-hours").pickadate({
                format: 'yyyy-mm-dd',
                formatSubmit: 'yyyy-mm-dd',
                min: [date.getFullYear(), date.getMonth(), date.getDate()],
                editable: true
            });
            $startDateOpeningHours.on('click', function () {
                let pickerStartDateOpeningHours = $startDateOpeningHours.pickadate('picker');
                pickerStartDateOpeningHours.open();
            });
        }

        $(document).on("change", "#location_restaurant", function () {
            if ($(this).is(":checked")) {
                if ($('.location-restaurant-field-wrapper').hasClass('d-none')) {
                    $('.location-restaurant-field-wrapper').removeClass('d-none');
                }
                $("#restaurant_preparation_time_kitchen").prop('required', true);
                $("#restaurant_preparation_time").prop('required', true);
            } else {
                if (!$('.location-restaurant-field-wrapper').hasClass('d-none')) {
                    $('.location-restaurant-field-wrapper').addClass('d-none');
                }
                $('#restaurant_preparation_time_kitchen').val(null);
                $('#restaurant_preparation_time').val(null);
                $("#restaurant_preparation_time_kitchen").prop('required', false);
                $("#restaurant_preparation_time").prop('required', false);
            }
        });
        $(document).on("change", "#location_takeaway", function () {
            if ($(this).is(":checked")) {
                if ($('.location-takeaway-field-wrapper').hasClass('d-none')) {
                    $('.location-takeaway-field-wrapper').removeClass('d-none');
                }
                $("#takeaway_preparation_time").prop('required', true);
                $("#number_of_days_you_can_order_before_pickup").prop('required', true);
            } else {
                $('#takeaway_preparation_time').val(null);
                $('#number_of_days_you_can_order_before_pickup').val(null);
                if (!$('.location-takeaway-field-wrapper').hasClass('d-none')) {
                    $('.location-takeaway-field-wrapper').addClass('d-none');
                }
                $("#takeaway_preparation_time").prop('required', false);
                $("#number_of_days_you_can_order_before_pickup").prop('required', false);
            }
        });
        $(document).on("change", "#waiting_time_message", function () {
            if ($(this).is(":checked")) {
                if ($('.waiting-time-message-field-wrapper').hasClass('d-none')) {
                    $('.waiting-time-message-field-wrapper').removeClass('d-none');
                }
                $("#minutes_before_waiting_time_message_activation").prop('required', true);
            } else {
                $('#minutes_before_waiting_time_message_activation').val(null);
                if (!$('.waiting-time-message-field-wrapper').hasClass('d-none')) {
                    $('.waiting-time-message-field-wrapper').addClass('d-none');
                }
                $("#minutes_before_waiting_time_message_activation").prop('required', false);
            }
        });
        $(document).on("change", "#payment_method_coupon", function () {
            if ($(this).is(":checked")) {
                if ($('.payment-method-coupon-field-wrapper').hasClass('d-none')) {
                    $('.payment-method-coupon-field-wrapper').removeClass('d-none');
                }
                $("#max_orders_per_day_per_person_with_payment_method_coupon").prop('required', true);
            } else {
                $('#max_orders_per_day_per_person_with_payment_method_coupon').val(null);
                if (!$('.payment-method-coupon-field-wrapper').hasClass('d-none')) {
                    $('.payment-method-coupon-field-wrapper').addClass('d-none');
                }
                $("#max_orders_per_day_per_person_with_payment_method_coupon").prop('required', false);
            }
        });
        $(document).on("change", "#location_restaurant_cart_customer_name", function () {
            if ($(this).is(":checked")) {
                if ($('#location-restaurant-cart-customer-name-mandatory-wrapper').hasClass('d-none')) {
                    $('#location-restaurant-cart-customer-name-mandatory-wrapper').removeClass('d-none');
                }
            } else {
                $('#location_restaurant_cart_customer_name_mandatory').prop('checked',false)
                if (!$('#location-restaurant-cart-customer-name-mandatory-wrapper').hasClass('d-none')) {
                    $('#location-restaurant-cart-customer-name-mandatory-wrapper').addClass('d-none');
                }
            }
        });
        $(document).on("change", "#location_restaurant_cart_customer_phone_number", function () {
            if ($(this).is(":checked")) {
                if ($('#location-restaurant-cart-customer-phone_number-mandatory-wrapper').hasClass('d-none')) {
                    $('#location-restaurant-cart-customer-phone_number-mandatory-wrapper').removeClass('d-none');
                }
            } else {
                $('#location_restaurant_cart_customer_phone_number_mandatory').prop('checked',false)
                if (!$('#location-restaurant-cart-customer-phone_number-mandatory-wrapper').hasClass('d-none')) {
                    $('#location-restaurant-cart-customer-phone_number-mandatory-wrapper').addClass('d-none');
                }
            }
        });
        $(document).on("change", "#recommended_max_orders_per_day_per_person_with_payment_method_coupon", function () {
            if (!checkMaxOrdersWithCoupon()) {
                alert($('#payment-method-coupon-field-wrapper').attr('data-recommended-max-coupons-message'));
                $(this).val('');
            }
        });
        $(document).on("change", "#max_orders_per_day_per_person_with_payment_method_coupon", function () {
            if (!checkMaxOrdersWithCoupon()) {
                alert($('#payment-method-coupon-field-wrapper').attr('data-recommended-max-coupons-message'));
                $(this).val('');
            }
            if (!checkTotalOrdersWithCoupon()) {
                alert($('#payment-method-coupon-field-wrapper').attr('data-max-coupons-message'));
                $(this).val('');
            }
        });
        $(document).on("change", "#max_orders_per_day_with_payment_method_coupon", function () {
            if (!checkTotalOrdersWithCoupon()) {
                alert($('#payment-method-coupon-field-wrapper').attr('data-max-coupons-message'));
                $(this).val('');
            }
        });

        function checkMaxOrdersWithCoupon() {
            let recommendedMaxOrdersPerDayPerPerson = $('#recommended_max_orders_per_day_per_person_with_payment_method_coupon').val();
            let maxOrdersPerDayPerPerson = $('#max_orders_per_day_per_person_with_payment_method_coupon').val();
            if (recommendedMaxOrdersPerDayPerPerson != '' && maxOrdersPerDayPerPerson != '' && !isNaN(recommendedMaxOrdersPerDayPerPerson) && !isNaN(maxOrdersPerDayPerPerson) && recommendedMaxOrdersPerDayPerPerson > maxOrdersPerDayPerPerson) {
                return false;
            }
            return true;
        }

        function checkTotalOrdersWithCoupon() {
            let maxOrdersPerDayPerPerson = parseInt($('#max_orders_per_day_per_person_with_payment_method_coupon').val());
            let totalOrdersPerDayPerPerson = parseInt($('#max_orders_per_day_with_payment_method_coupon').val());
            if (totalOrdersPerDayPerPerson != '' && maxOrdersPerDayPerPerson != '' && !isNaN(totalOrdersPerDayPerPerson) && !isNaN(maxOrdersPerDayPerPerson) && maxOrdersPerDayPerPerson > totalOrdersPerDayPerPerson) {
                return false;
            }
            return true;
        }

    }
);