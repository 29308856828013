$(document).ready(
    function () {

        let p = $("body").attr("p");

        if (p == 'organizer_location_statistic') {

            $(document).on("click", "#open-statistic-filter-wrapper-button", function () {
                if ($('#statistic-filter-wrapper').hasClass('d-none')) {
                    $('#statistic-filter-wrapper').removeClass('d-none');
                } else {
                    $('#statistic-filter-wrapper').addClass('d-none');
                }
                return false;
            });

            $(document).on("change", ".statistic-trigger", function () {
                if ($(this).hasClass('statistic-location') && !$(this).is(":checked")) {
                    $("input.all-locations").prop("checked", false);
                }
                getLocationStatistic();
            });
            $(document).on("change", ".all-locations", function () {
                if ($(this).is(":checked")) {
                    $("input.statistic-location").prop("checked", true);
                    getLocationStatistic();
                }
            });

            $(document).on("click", "#get-statistic-from-last-order-pos-registration-date-button", function (e) {
                e.preventDefault();
                getLocationStatistic(true);
            });

            getLocationStatistic();

            function getLocationStatistic(sinceLastOrderPosRegistration = false) {

                let formData = new FormData(document.getElementById('location-statistic-form'));
                if ($("body").attr("employer_token") != undefined) {
                    formData.append('employer_token', $("body").attr("employer_token"));
                }
                if (sinceLastOrderPosRegistration === true) {
                    formData.append('since_last_order_pos_registration', 'true');
                }

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_location_statistic/get_statistic",
                    data: formData,
                    async: true,
                    cache: false,
                    processData: false,
                    contentType: false
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $('#location-statistic').empty();
                            $('#location-statistic').append(data.html);
                        }

                        if (data.date_for_order_pos_registration !== undefined && data.date_for_order_pos_registration !== null) {
                            $('#order-pos-registration-register-button').attr('data-date-for-order-pos-registration', data.date_for_order_pos_registration);
                        }
                        if (data.start_date !== undefined && data.start_date !== null) {
                            $('#start_date').val(data.start_date);
                        }
                        if (data.start_time !== undefined && data.start_time !== null) {
                            $('#start_time').val(data.start_time);
                        }
                        if (data.end_date !== undefined && data.end_date !== null) {
                            $('#end_date').val(data.end_date);
                        }
                        if (data.end_time !== undefined && data.end_time !== null) {
                            $('#end_time').val(data.end_time);
                        }
                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })
            }

        }

    }
);

