$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'organizer_event_ticket_category' && a === 'list') {

            let order_by = 'name';
            let order_by_direction = 'asc';

            $(document).on("click", ".pagination a", function (e) {
                e.preventDefault();
                let page = parseInt($(this).attr("href").substring(6));
                let location_id = $("body").attr("event_id");
                let search_string = $("#product-search-button").attr("data-search-string");
                let product_type = $("#product_type").val();
                getProductList(location_id, page, order_by, order_by_direction, search_string, product_type);
            });

            $(document).on("click", ".sort", function (e) {
                e.preventDefault();
                let page = 1;
                let location_id = $("body").attr("event_id");
                let search_string = $("#product-search-button").attr("data-search-string");
                order_by = $(this).attr('data-order-by');
                order_by_direction = $(this).attr('data-order-by-direction');
                let product_type = $("#product_type").val();
                getProductList(location_id, page, order_by, order_by_direction, search_string, product_type);
            });

            function getProductList(location_id = null, page = 1, order_by, order_by_direction, search_string = '', product_type) {

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_ticket_category/search",
                    data: {
                        location_id,
                        page,
                        search_string,
                        order_by,
                        order_by_direction,
                        product_type
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $("#product-list").empty();
                            $('#product-list').append(data.html);
                        }

                        $('html,body').scrollTop(0);

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })
            }

        }

    });