$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'order_confirmation') {

            $(document).on("submit", "#receipt-in-email", function () {

                $('.alert').remove();


                $.ajax({
                    type: 'POST',
                    url: "/ajax/order/send_receipt_in_email",
                    data: {
                        serialized_post: $(this).serialize()
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            prependAlert('#receipt-in-email-button-wrapper', data.html, 'success', null, '#receipt-in-email');
                            $("#receipt-in-email").remove();
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            prependAlert('#receipt-in-email-button-wrapper', data.responseJSON.error_message, 'danger', null, '#receipt-in-email');
                        }

                    });

                return false;

            });

        }

    });