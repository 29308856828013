$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'organizer_product_category_link' && a === 'list') {

            $('select[name=product_id]').select2({
                theme: 'bootstrap4',
            });

        }

        $('body').scrollspy({
            target: '#navbar-product-category',
            offset: 60
        });

    }
);