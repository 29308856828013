let locationCart = {}
let getCartModal;
let updateCartSummary;
let updateMenuButtons;

function setLocationCart(data) {
    if (data.location_id !== undefined && data.location_id !== null) {
        locationCart.location_id = data.location_id;
        if (data.location_type !== undefined && data.location_type !== null) {
            locationCart.location_type = data.location_type;
        }
        if (data.table_number !== undefined && data.table_number !== null) {
            locationCart.table_number = data.table_number;
        }
        if (data.number_of_products !== undefined && data.number_of_products !== null) {
            locationCart.number_of_products = data.number_of_products;
        }
        if (data.amount !== undefined && data.amount !== null) {
            locationCart.amount = data.amount;
        }
        if (data.tips_percent !== undefined && data.tips_percent !== null) {
            locationCart.tips_percent = data.tips_percent;
        }
        if (data.payment_method !== undefined && data.payment_method !== null) {
            locationCart.payment_method = data.payment_method;
        }
        if (data.comment !== undefined && data.comment !== null) {
            locationCart.comment = data.comment;
        }
        if (data.name !== undefined && data.name !== null) {
            locationCart.name = data.name;
        }
        if (data.phone_number !== undefined && data.phone_number !== null) {
            locationCart.phone_number = data.phone_number;
        }
        if (data.pickup_date !== undefined && data.pickup_date !== null) {
            locationCart.pickup_date = data.pickup_date;
        }
        if (data.pickup_time !== undefined && data.pickup_time !== null) {
            locationCart.pickup_time = data.pickup_time;
        }
        if (data.promo_code !== undefined && data.promo_code !== null) {
            locationCart.promo_code = data.promo_code;
        }
        if (data.pick_up_at_the_bar !== undefined && data.pick_up_at_the_bar !== null) {
            locationCart.pick_up_at_the_bar = data.pick_up_at_the_bar;
        }
        if (data.products !== undefined && data.products !== null) {
            for (let i = 0; i < data.products.length; i++) {
                let productObj = JSON.parse(data.products[i]);
                let productExtras = [];
                for (let j = 0; j < productObj.product_extra.length; j++) {
                    let productExtraObj = JSON.parse(productObj.product_extra[j]);
                    let productExtra = {
                        product_extra_id: productExtraObj.product_extra_id,
                        amount: productExtraObj.amount
                    }
                    productExtras.push(productExtra);
                }
                let product = {
                    product_id: productObj.product_id,
                    amount: productObj.amount,
                    comment: productObj.comment
                }
                if (productExtras.length) {
                    product.product_extra = productExtras;
                }
                locationCart.products.push(product);
            }
        }

        if (data.order_storage_ids !== undefined && data.order_storage_ids !== null) {
            orderStorageIds = data.order_storage_ids;
        }
        updateCartSummary();
        updateMenuButtons();
        if (data.set_payment_failed !== undefined && data.set_payment_failed !== null && data.set_payment_failed === true) {
            locationCart.payment_failed = true;
        }
        locationCart.order_storage_identifier = '';
        getCartModal();
        if (data.set_payment_failed !== undefined && data.set_payment_failed !== null && data.set_payment_failed === true) {
            delete locationCart.payment_failed;
        }
    }
}

$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'events') {

            let locationId = $('#location').attr('data-location-id');
            let locationType = $('#location-products').attr('data-location-type');
            locationCart = {
                location_id: locationId,
                location_type: locationType,
                table_number: '',
                number_of_products: 0,
                amount: 0,
                tips_percent: 0,
                products: [],
                payment_method: '',
                comment: '',
                name: '',
                phone_number: '',
                pickup_date: '',
                pickup_time: '',
                promo_code: '',
                pick_up_at_the_bar: false,
                order_storage_identifier: ''
            }

            function getCartInfoFromSession() {
                $.ajax({
                    type: 'GET',
                    url: "/ajax/order/get_cart_from_session"
                })
                    .done(function (data) {
                        if (data.location_id !== undefined && data.location_id !== null && data.location_id == locationId) {
                            data.set_payment_failed = true;
                            setLocationCart(data);
                        }
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    })
            }

            if (!($(".clear-shopping-cart").length > 0)) {
                getCartInfoFromSession();
            }

            updateMenuButtons = function () {
                if (locationCart.products.length) {
                    for (let i = 0; i < locationCart.products.length; i++) {
                        let productId = locationCart.products[i].product_id;
                        let input = $('#product-id-' + productId);
                        input.val(locationCart.products[i].amount)
                        let addToCartButton = input.siblings('button.add-product');
                        if (input.val() > 0 && !(addToCartButton.attr('data-has-product-extra') == 1 && addToCartButton.attr('data-force-product-extra-popup') == 1)) {
                            if (!input.siblings("span").hasClass('d-none')) {
                                input.siblings("span").addClass('d-none');
                            }
                            if (input.siblings(".remove-product").hasClass('d-none')) {
                                input.siblings(".remove-product").removeClass('d-none');
                            }
                            if (input.hasClass('d-none')) {
                                input.removeClass('d-none');
                            }
                        }
                    }
                }
            }

            function areEqual(arr1, arr2) {
                if (arr1.length !== arr2.length) {
                    return false;
                }
                for (let i = 0; i < arr1.length; i++) {
                    if (JSON.stringify(arr1) !== JSON.stringify(arr2)) {
                        return false;
                    }
                }
                return true;
            }

            $(document).on("click", "#add-to-cart-button", function () {
                let numberOfProduct = parseInt($("input[name='product-to-order']").val());
                let productId = parseInt($("input[name='product-to-order']").attr('data-product-id'));
                let comment = $("textarea[name='comment']").val();
                let productExtraArray = $("input.product-extra-to-order");
                let productExtraTextArray = $("input.product-extra-text-to-order");
                let productExtras = [];

                productExtraArray.each(function () {
                    if (parseInt($(this).val()) > 0) {
                        let productExtra = {
                            product_extra_id: parseInt($(this).attr('data-product-extra-id')),
                            amount: parseInt($(this).val())
                        }
                        productExtras.push(productExtra);
                    }
                });
                productExtraTextArray.each(function () {
                    if ($(this).is(":checked")) {
                        let productExtra = {
                            product_extra_id: parseInt($(this).attr('data-product-extra-id')),
                            checked: true
                        }
                        productExtras.push(productExtra);
                    }
                });
                productExtras.sort(function (a, b) {
                    return (a['product_extra_id'] > b['product_extra_id']) ? 1 : ((a['product_extra_id'] < b['product_extra_id']) ? -1 : 0);
                });
                let allProducts = locationCart.products;

                let productInCart = false;
                if (productExtras.length > 0) {
                    if (allProducts.length > 0) {
                        for (let [index, product] of allProducts.entries()) {
                            if (product.product_id === productId) {
                                if ('product_extra' in product) {
                                    if (areEqual(product.product_extra, productExtras) === true) {
                                        locationCart.products[index].amount = locationCart.products[index].amount + numberOfProduct;
                                        locationCart.products[index].comment = comment;
                                        productInCart = true;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    if (allProducts.length > 0) {
                        for (let product of allProducts) {
                            if (product.product_id === productId) {
                                if (!('product_extra' in product)) {
                                    let productIndex = locationCart.products.findIndex((obj => obj.product_id === product.product_id));
                                    if (productIndex !== -1) {
                                        locationCart.products[productIndex].amount = locationCart.products[productIndex].amount + numberOfProduct;
                                        locationCart.products[index].comment = comment;
                                        productInCart = true;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }

                if (productInCart === false) {
                    let product = {
                        product_id: productId,
                        amount: numberOfProduct,
                        comment: comment
                    }
                    if (productExtras.length > 0) {
                        product.product_extra = productExtras;
                    }
                    locationCart.products.push(product);
                }
                let priceInclVat = parseFloat($('#subtotal').text().replace(',', '.'));
                locationCart.number_of_products = parseInt(locationCart.number_of_products) + numberOfProduct;
                locationCart.amount = parseFloat(locationCart.amount) + priceInclVat;
                updateCartSummary();
                if ($('#footer-space-for-cart').hasClass('d-none')) {
                    $('#footer-space-for-cart').removeClass('d-none');
                }
                if (!$('#footer-space-for-cart').hasClass('d-block')) {
                    $('#footer-space-for-cart').addClass('d-block');
                }
                $('#product-extra-modal').modal('hide');
            });

            $(document).on("click", ".add-product-with-extra-products", function () {
                let numberOfProduct = parseInt($(this).siblings("input.product-to-order").val());
                let productPrice = parseFloat($(this).attr('data-price-incl-vat'));
                let subTotal = parseFloat($('#subtotal').text().replace(',', '.'));
                let sumPriceExtra = subTotal - (productPrice * numberOfProduct);
                let sumPriceExtraPerProduct = sumPriceExtra / numberOfProduct;
                numberOfProduct = numberOfProduct + 1;
                subTotal = ((productPrice + sumPriceExtraPerProduct) * numberOfProduct).toFixed(2);
                $(this).siblings("input.product-to-order").val(numberOfProduct);
                $('#subtotal').text(subTotal.replace('.', ',').replace(',00', ''));
            });

            $(document).on("click", ".remove-product-with-extra-products", function () {
                let numberOfProduct = parseInt($(this).siblings("input.product-to-order").val());
                if (numberOfProduct === 1) {
                    return false;
                }
                let productPrice = parseFloat($(this).attr('data-price-incl-vat'));
                let subTotal = parseFloat($('#subtotal').text().replace(',', '.'));
                let sumPriceExtra = subTotal - (productPrice * numberOfProduct);
                let sumPriceExtraPerProduct = sumPriceExtra / numberOfProduct;
                numberOfProduct = numberOfProduct - 1;
                subTotal = ((productPrice + sumPriceExtraPerProduct) * numberOfProduct).toFixed(2);
                $(this).siblings("input.product-to-order").val(numberOfProduct);
                $('#subtotal').text(subTotal.replace('.', ',').replace(',00', ''));
            });

            $(document).on("click", ".add-product-extra", function () {
                if (!$(this).siblings("span").hasClass('d-none')) {
                    $(this).siblings("span").addClass('d-none');
                }
                if ($(this).siblings(".remove-product-extra").hasClass('d-none')) {
                    $(this).siblings(".remove-product-extra").removeClass('d-none');
                }
                if ($(this).siblings("input.product-extra-to-order").hasClass('d-none')) {
                    $(this).siblings("input.product-extra-to-order").removeClass('d-none');
                }
                let numberOfProductExtra = parseInt($(this).siblings("input.product-extra-to-order").val());
                let productExtraPrice = parseFloat($(this).attr('data-price-incl-vat'));
                let productId = parseFloat($(this).attr('data-product-id'));
                let numberOfProduct = parseInt($('#product-with-extra-products-id-' + productId).val());
                numberOfProductExtra = numberOfProductExtra + 1;
                $(this).siblings("input.product-extra-to-order").val(numberOfProductExtra);
                let subTotal = parseFloat($('#subtotal').text().replace(',', '.'));
                subTotal = (subTotal + (productExtraPrice * numberOfProduct)).toFixed(2);
                $('#subtotal').text(subTotal.replace('.', ',').replace(',00', ''));
            });

            $(document).on("click", ".remove-product-extra", function () {
                let numberOfProductExtra = parseInt($(this).siblings("input.product-extra-to-order").val());
                let productExtraPrice = parseFloat($(this).attr('data-price-incl-vat'));
                let productId = parseFloat($(this).attr('data-product-id'));
                let numberOfProduct = parseInt($('#product-with-extra-products-id-' + productId).val());
                numberOfProductExtra = numberOfProductExtra - 1;
                $(this).siblings("input.product-extra-to-order").val(numberOfProductExtra);
                let subTotal = parseFloat($('#subtotal').text().replace(',', '.'));
                subTotal = (subTotal - (productExtraPrice * numberOfProduct)).toFixed(2);
                $('#subtotal').text(subTotal.replace('.', ',').replace(',00', ''));
                if (numberOfProductExtra === 0) {
                    if ($(this).siblings("span").hasClass('d-none')) {
                        $(this).siblings("span").removeClass('d-none');
                    }
                    if (!$(this).hasClass('d-none')) {
                        $(this).addClass('d-none');
                    }
                    if (!$(this).siblings("input.product-extra-to-order").hasClass('d-none')) {
                        $(this).siblings("input.product-extra-to-order").addClass('d-none');
                    }
                }

            });

            $(document).on("change", "div.product-qty-container-wrapper input.product-to-order", function () {
                let productId = parseInt($(this).attr('data-product-id'));
                let productIndex = locationCart.products.findIndex((obj => obj.product_id === productId));
                let productAmount = parseInt($(this).val());
                let priceInclVat = parseFloat($(this).attr('data-price-incl-vat'));
                if (productAmount > locationCart.products[productIndex].amount) {
                    let productAmountDiff = productAmount - parseInt(locationCart.products[productIndex].amount);
                    locationCart.number_of_products = parseInt(locationCart.number_of_products) + productAmountDiff;
                    let priceInclVatDiff = productAmountDiff * priceInclVat;
                    locationCart.amount = parseFloat(locationCart.amount) + priceInclVatDiff;
                }

                if (productAmount < locationCart.products[productIndex].amount) {
                    let productAmountDiff = parseInt(locationCart.products[productIndex].amount) - productAmount;
                    locationCart.number_of_products = parseInt(locationCart.number_of_products) - productAmountDiff;
                    let priceInclVatDiff = productAmountDiff * priceInclVat;
                    locationCart.amount = parseFloat(locationCart.amount) - priceInclVatDiff;
                }
                locationCart.products[productIndex].amount = productAmount;
                updateCartSummary();

            });


            let numberOfExtraProduct;
            $(document).on("focusin", "div#product-extra-modal input.product-to-order", function () {
                numberOfExtraProduct = $(this).val();
            });

            $(document).on("change", "div#product-extra-modal input.product-to-order", function () {
                let numberOfProduct = parseInt($(this).val());
                let productPrice = parseFloat($(this).attr('data-price-incl-vat'));
                let subTotal = parseFloat($('#subtotal').text().replace(',', '.'));
                let sumPriceExtra = subTotal - (productPrice * numberOfExtraProduct);
                let sumPriceExtraPerProduct = sumPriceExtra / numberOfExtraProduct;
                subTotal = ((productPrice + sumPriceExtraPerProduct) * numberOfProduct).toFixed(2);
                $('#subtotal').text(subTotal.replace('.', ',').replace(',00', ''));
            });

            let numberOfExtraProductExtra;
            $(document).on("focusin", "div#product-extra-modal input.product-extra-to-order", function () {
                numberOfExtraProductExtra = $(this).val();
            });

            $(document).on("change", "div#product-extra-modal input.product-extra-to-order", function () {
                let numberOfProductExtra = parseInt($(this).val());
                let productExtraPrice = parseFloat($(this).attr('data-price-incl-vat'));
                let productId = parseInt($(this).attr('data-product-id'));
                let numberOfProduct = parseInt($('#product-with-extra-products-id-' + productId).val());
                let subTotal = parseFloat($('#subtotal').text().replace(',', '.'));
                if (numberOfExtraProductExtra > numberOfProductExtra) {
                    let numberOfProductExtraDiff = numberOfExtraProductExtra - numberOfProductExtra;
                    subTotal = (subTotal - ((numberOfProductExtraDiff * productExtraPrice) * numberOfProduct)).toFixed(2);
                }
                if (numberOfExtraProductExtra < numberOfProductExtra) {
                    let numberOfProductExtraDiff = numberOfProductExtra - numberOfExtraProductExtra;
                    subTotal = (subTotal + ((numberOfProductExtraDiff * productExtraPrice) * numberOfProduct)).toFixed(2);
                }
                $('#subtotal').text(subTotal.replace('.', ',').replace(',00', ''));
            });

            $(document).on("click", ".add-product", function () {
                let productId = parseInt($(this).attr('data-product-id'));
                if ($(this).attr('data-has-product-extra') == 1 && $(this).attr('data-force-product-extra-popup') == 1) {
                    getProductExtraModal(productId);
                    return false;
                } else {
                    if (!$(this).siblings("span").hasClass('d-none')) {
                        $(this).siblings("span").addClass('d-none');
                    }
                    if ($(this).siblings(".remove-product").hasClass('d-none')) {
                        $(this).siblings(".remove-product").removeClass('d-none');
                    }
                    if ($(this).siblings("input.product-to-order").hasClass('d-none')) {
                        $(this).siblings("input.product-to-order").removeClass('d-none');
                    }
                    let productIndex = locationCart.products.findIndex((obj => obj.product_id === productId));
                    let productAmount;
                    if (productIndex === -1) {
                        productAmount = 1;
                        let product = {
                            product_id: productId,
                            amount: productAmount
                        }
                        locationCart.products.push(product);
                    } else {
                        productAmount = parseInt(locationCart.products[productIndex].amount) + 1
                        locationCart.products[productIndex].amount = productAmount;
                    }
                    let priceInclVat = parseFloat($(this).attr('data-price-incl-vat'));
                    locationCart.number_of_products = parseInt(locationCart.number_of_products) + 1;
                    locationCart.amount = parseFloat(locationCart.amount) + priceInclVat;
                    updateCartSummary();
                    $('input#product-id-' + productId).val(productAmount);
                    if ($('#footer-space-for-cart').hasClass('d-none')) {
                        $('#footer-space-for-cart').removeClass('d-none');
                    }
                    if (!$('#footer-space-for-cart').hasClass('d-block')) {
                        $('#footer-space-for-cart').addClass('d-block');
                    }
                }
            });

            $(document).on("click", "#update-extra-product-button", function () {
                let productIndex = parseInt($(this).data('cart-index'));
                let productExtraArray = $("input.product-extra-to-order");
                let productExtraTextArray = $("input.product-extra-text-to-order");
                let productExtras = [];
                productExtraArray.each(function () {
                    if (parseInt($(this).val()) > 0) {
                        let productExtra = {
                            product_extra_id: parseInt($(this).attr('data-product-extra-id')),
                            amount: parseInt($(this).val())
                        }
                        productExtras.push(productExtra);
                    }
                });
                productExtraTextArray.each(function () {
                    if ($(this).is(":checked")) {
                        let productExtra = {
                            product_extra_id: parseInt($(this).attr('data-product-extra-id')),
                            checked: true
                        }
                        productExtras.push(productExtra);
                    }
                });
                productExtras.sort(function (a, b) {
                    return (a['product_extra_id'] > b['product_extra_id']) ? 1 : ((a['product_extra_id'] < b['product_extra_id']) ? -1 : 0);
                });
                locationCart.products[productIndex].product_extra = productExtras;
                $('#cart-modal').modal('hide');
                $('#product-extra-modal').modal('hide');
                getCartModal();
            });

            $(document).on("click", "td.product-title", function () {
                let productId = parseInt($(this).attr('data-product-id'));
                let productIndex = parseInt($(this).data('cart-index'));
                let comment = locationCart.products[productIndex].comment;
                if ('product_extra' in locationCart.products[productIndex]) {
                    let productExtra = locationCart.products[productIndex].product_extra;
                    getProductExtraModal(productId, 1, productIndex, comment, productExtra);
                } else {
                    getProductExtraModal(productId, 1, productIndex, comment);
                }
            });


            function getProductExtraModal(productId, update = 0, productIndex = null, comment = '', productExtra = [""]) {
                if ($('#product-extra-modal').length) {
                    $('#product-extra-modal').remove();
                }

                let data = {
                    location_id: locationId,
                    product_id: productId,
                    location_type: locationCart.location_type,
                    product_extra: productExtra,
                    update: update,
                    comment: comment
                }
                if (productIndex !== null) {
                    data.cart_index = productIndex;
                }
                if (locationCart.promo_code != '') {
                    data.promo_code = locationCart.promo_code;
                }
                $.ajax({
                    type: 'POST',
                    url: "/ajax/order/get_product_extra",
                    data: data
                })
                    .done(function (data) {
                        if (data.html !== undefined && data.html !== null) {
                            $("#event-public-list-organizer").after(data.html);
                            $('#product-extra-modal').modal('show');
                        }
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    })
            }

            updateCartSummary = function () {
                $('#location-cart-summary-number-of-products').text(locationCart.number_of_products);
                $('#location-cart-summary-amount').text(locationCart.amount.toFixed(2).replace('.', ','));
                if (locationCart.number_of_products == 0 && !$('#location-cart-summary').hasClass('d-none')) {
                    $('#location-cart-summary').addClass('d-none');
                }
                if (locationCart.number_of_products > 0 && $('#location-cart-summary').hasClass('d-none')) {
                    $('#location-cart-summary').removeClass('d-none');
                }
            }

            $(document).on("click", ".remove-product", function () {
                let productId = parseInt($(this).attr('data-product-id'));
                let productIndex = locationCart.products.findIndex((obj => obj.product_id === productId));
                let productAmount = parseInt(locationCart.products[productIndex].amount) - 1;
                locationCart.products[productIndex].amount = productAmount;
                let priceInclVat = parseFloat($(this).attr('data-price-incl-vat'));
                locationCart.number_of_products = parseInt(locationCart.number_of_products) - 1;
                locationCart.amount = parseFloat(locationCart.amount) - priceInclVat;
                updateCartSummary();
                $('input#product-id-' + productId).val(productAmount);
                if (productAmount === 0) {
                    if (!$('input#product-id-' + productId).hasClass('d-none')) {
                        $('input#product-id-' + productId).addClass('d-none');
                    }
                    if ($(this).siblings("span").hasClass('d-none')) {
                        $(this).siblings("span").removeClass('d-none');
                    }
                    $(this).addClass('d-none');
                    if (!$('#footer-space-for-cart').hasClass('d-none')) {
                        $('#footer-space-for-cart').addClass('d-none');
                    }
                    if ($('#footer-space-for-cart').hasClass('d-block')) {
                        $('#footer-space-for-cart').removeClass('d-block');
                    }
                }
            });

            getCartModal = function (autoPayWithTerminal) {
                for (let [index, product] of locationCart.products.entries()) {
                    if (product.amount === 0) {
                        locationCart.products.splice(index, 1);
                    }
                }

                if (locationCart.products.length > 0) {

                    let data = {
                        location_id: locationId,
                        location_type: locationCart.location_type,
                        table_number: locationCart.table_number,
                        products: locationCart.products,
                        tips_percent: locationCart.tips_percent,
                        payment_method: locationCart.payment_method,
                        comment: locationCart.comment,
                        promo_code: locationCart.promo_code
                    };
                    if (locationCart.payment_failed === true) {
                        data.payment_failed = locationCart.payment_failed;
                    }

                    if (locationCart.name != '') {
                        data.name = locationCart.name;
                    }

                    if ($.isNumeric(locationCart.phone_number)) {
                        data.phone_number = locationCart.phone_number;
                    }

                    if (locationCart.pick_up_at_the_bar == true) {
                        data.pick_up_at_the_bar = locationCart.pick_up_at_the_bar;
                    }
                    if (orderStorageIds !== null) {
                        data.order_storage_ids = orderStorageIds;
                    }

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/order/get_cart",
                        data: data
                    })
                        .done(function (data) {

                            if (data.html !== undefined && data.html !== null) {
                                if ($('#product-extra-modal').length) {
                                    $('#product-extra-modal').remove();
                                }
                                if ($('#cart-modal').length) {
                                    $('#cart-modal').remove();
                                }
                                $("#event-public-list-organizer").after(data.html);

                                if (locationCart.order_storage_identifier != null && locationCart.order_storage_identifier != '') {
                                    locationCart.payment_method = 'none';
                                    $('form#order_register_form').submit();
                                } else if (autoPayWithTerminal !== undefined && autoPayWithTerminal !== null && autoPayWithTerminal === true) {
                                    locationCart.payment_method = 'payment_terminal';
                                    $('form#order_register_form').submit();
                                } else {
                                    $('#cart-modal').modal('show');
                                }
                            }

                        })
                        .fail(function (data) {
                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }
                            cartQuickPaymentTerminalLock = false;
                        })
                }
            }

            $(document).on("click", ".cart-open", function () {
                locationCart.order_storage_identifier = '';
                getCartModal();
            });

            $(document).on("click", ".add-tips", function () {
                let tipsPercent = parseInt($(this).attr('data-tips-percent'));
                if (tipsPercent === locationCart.tips_percent) {
                    locationCart.tips_percent = 0;
                    $(this).removeClass('btn-primary');
                    $(this).addClass('btn-light');
                } else {
                    locationCart.tips_percent = tipsPercent;
                    $('.add-tips').removeClass('btn-primary');
                    $('.add-tips').addClass('btn-light');
                    $(this).removeClass('btn-light');
                    $(this).addClass('btn-primary');
                }
                updateTips();
                if (locationCart.tips_percent == 0) {
                    if (!$('.cart-summary-total').hasClass('d-none')) {
                        $('.cart-summary-total').addClass('d-none')
                    }
                    if (!$('.cart-summary-tips').hasClass('d-none')) {
                        $('.cart-summary-tips').addClass('d-none')
                    }
                } else {
                    if ($('.cart-summary-total').hasClass('d-none')) {
                        $('.cart-summary-total').removeClass('d-none')
                    }
                    if ($('.cart-summary-tips').hasClass('d-none')) {
                        $('.cart-summary-tips').removeClass('d-none')
                    }
                }
            });

            function updateTips() {
                $('#cart-tips-percent').text(locationCart.tips_percent);
                let tips = Math.round(locationCart.amount * locationCart.tips_percent / 100);
                $('#cart-tips').text(tips.toFixed(2).replace('.', ','));
                let amountWithTips = (locationCart.amount + tips).toFixed(2);
                $('#cart-amount-with-tips').text(amountWithTips.replace('.', ','));
                $('#order-button-amount').text(amountWithTips.replace('.', ','));
                $('#tipping').val((locationCart.amount * locationCart.tips_percent / 100).toFixed(2).replace('.', ','));
            }

            $(document).on("click", ".cart-add-product", function () {
                let productId = $(this).data('product-id');
                let productIndex = parseInt($(this).data('cart-index'));
                let productAmount = parseInt(locationCart.products[productIndex].amount);
                let cartAmount = parseInt(locationCart.number_of_products);
                productAmount += 1;
                cartAmount += 1;
                locationCart.products[productIndex].amount = productAmount;
                locationCart.number_of_products = cartAmount;
                let productPrice = parseFloat($(this).siblings('.cart-product-amount').data('product-price-incl-vat'));
                let productTotalPrice = (productPrice * productAmount).toFixed(2);
                $(this).closest('td').find('.product-total-price').text(productTotalPrice.replace('.', ','));
                $(this).siblings('.cart-product-amount').text(productAmount);
                $('input#product-id-' + productId).val(productAmount);
                $('input#product-cart-index-' + productIndex).val(productAmount);
                addPriceToCart(productPrice);
                updateCartSummary();
            });

            $(document).on("click", ".cart-remove-product", function () {
                let productId = $(this).data('product-id');
                let productIndex = parseInt($(this).data('cart-index'));
                let productAmount = parseInt(locationCart.products[productIndex].amount);
                let cartAmount = parseInt(locationCart.number_of_products);
                productAmount -= 1;
                cartAmount -= 1;
                locationCart.products[productIndex].amount = productAmount;
                locationCart.number_of_products = cartAmount;
                let productPrice = parseFloat($(this).siblings('.cart-product-amount').data('product-price-incl-vat'));
                subtractPriceToCart(productPrice);
                $('input#product-id-' + productId).val(productAmount);
                $('input#product-cart-index-' + productIndex).val(productAmount);
                if (productAmount === 0) {
                    $(this).closest('tr').remove();
                    if (!$('input#product-id-' + productId).hasClass('d-none')) {
                        $('input#product-id-' + productId).addClass('d-none');
                    }
                    if ($('input#product-id-' + productId).siblings("span").hasClass('d-none')) {
                        $('input#product-id-' + productId).siblings("span").removeClass('d-none');
                    }
                    $('input#product-id-' + productId).siblings("button.remove-product").addClass('d-none');
                    if (locationCart.number_of_products == 0) {
                        $('#cart-modal').modal('hide');
                    }
                } else {
                    locationCart.products[productIndex].amount = productAmount;
                    let productTotalPrice = (productPrice * productAmount).toFixed(2);
                    $(this).closest('td').find('.product-total-price').text(productTotalPrice.replace('.', ','));
                    $(this).siblings('.cart-product-amount').text(productAmount);
                }
                updateCartSummary();
            });


            $(document).on("change", "#table_number", function () {
                let tableNumber = $(this).val();
                locationCart.table_number = tableNumber;
            });

            $(document).on("change", "#name", function () {
                let name = $(this).val();
                locationCart.name = name;
            });

            $(document).on("change", "#phone_number", function () {
                let phoneNumber = $(this).val();
                locationCart.phone_number = phoneNumber;
            });

            $(document).on("change", "#pickup_date", function () {
                let pickupDate = $(this).val();
                locationCart.pickup_date = pickupDate;
            });

            $(document).on("change", "#pickup_time", function () {
                let pickupTime = $(this).val();
                locationCart.pickup_time = pickupTime;
            });


            function subtractPriceToCart(productPrice) {
                locationCart.amount = locationCart.amount - productPrice
                updateCart();
            }

            function addPriceToCart(productPrice) {
                locationCart.amount = locationCart.amount + productPrice
                updateCart();
            }

            function updateCart() {
                $('#cart-amount').text(locationCart.amount.toFixed(2));
                let tips = Math.round(locationCart.amount * locationCart.tips_percent / 100);
                $('#cart-tips').text(tips.toFixed(2).replace('.', ','));
                let amountWithTips = (locationCart.amount + tips).toFixed(2);
                $('#cart-amount-with-tips').text(amountWithTips.replace('.', ','));
                $('#order-button-amount').text(amountWithTips.replace('.', ','));
            }


            $(document).on("click", "#cart-comment-button", function () {
                if ($('#cart-comment').hasClass('d-none')) {
                    $('#cart-comment').removeClass('d-none');
                } else {
                    $('#cart-comment').addClass('d-none');
                }
                return false;
            });

            $(document).on("click", "#product-extra-comment-button", function () {
                if ($('#product-extra-comment').hasClass('d-none')) {
                    $('#product-extra-comment').removeClass('d-none');
                }
                if ($('#product-extra-comment-label').hasClass('d-none')) {
                    $('#product-extra-comment-label').removeClass('d-none');
                }
                $(this).remove();

            });

            $(document).on("change", "#cart-comment", function () {
                locationCart.comment = $('#cart-comment').val();
            });


            $(document).on("click", "#term-button", function () {

                return false;
            });


            $(document).on("click", ".get-products", function () {
                let btn = $(this);
                let locationId = $(this).attr('data-location-id');
                let locationType = $(this).attr('data-location-type');

                if (locationCart.number_of_products > 0) {
                    changeLocationType(locationId, locationType, locationCart).done(function (data) {
                        if (data.are_products_available === false) {
                            let confirmMessage = btn.attr('data-location-type-confirm-message');
                            if (!confirm(confirmMessage)) {
                                $("input[name='inlineRadioOptions'][data-location-type='" + locationType + "']").prop("checked", false);
                                $("input[name='inlineRadioOptions'][data-location-type='" + locationCart.location_type + "']").prop("checked", true);
                                return false;
                            }
                            emptyCart();
                        } else {
                            locationCart.amount = data.cart_total_price;
                        }
                        locationCart.location_type = locationType;
                        updateCartSummary();
                        getProducts(locationId, locationType, locationCart.products);
                    });
                } else {
                    locationCart.location_type = locationType;
                    getProducts(locationId, locationType);
                }

            });

            $(document).on("click", "#open-promo-code-form-button", function () {
                if (!$('#promo-code-alert-message').hasClass('d-none')) {
                    $('#promo-code-alert-message').addClass('d-none');
                }
                if ($('#location-promo-code-form-wrapper').css('display') == 'none') {
                    $('#location-promo-code-form-wrapper').css('display', '');
                }
                if ($('#location-promo-code-form').hasClass('d-none')) {
                    $('#location-promo-code-form').removeClass('d-none');
                }
                if ($('#location-promo-code-form-wrapper').hasClass('d-none')) {
                    $('#location-promo-code-form-wrapper').removeClass('d-none');
                } else {
                    $('#location-promo-code-form-wrapper').addClass('d-none');
                }
            });

            $(document).on("keypress", "#promo_code", function (e) {
                if (e.which === 13) {
                    e.preventDefault();
                    activatePromoCode();
                }
            });

            $(document).on("click", "#promo-code-button", function () {
                activatePromoCode();
            });

            function activatePromoCode() {
                let promoCode = $('#promo_code').val();
                if (promoCode == '') {
                    return false;
                }

                let promoCodeIsValid = false;
                checkPromoCode(locationId, promoCode, function (promoCodeIsValidBool) {
                    promoCodeIsValid = promoCodeIsValidBool;
                });

                setTimeout(function () {
                    if (!promoCodeIsValid) {
                        if ($('#promo-code-alert-message').hasClass('alert-success')) {
                            $('#promo-code-alert-message').removeClass('alert-success');
                        }
                        if (!$('#promo-code-alert-message').hasClass('alert-danger')) {
                            $('#promo-code-alert-message').addClass('alert-danger');
                        }
                        if ($('#promo-code-alert-message').hasClass('d-none')) {
                            $('#promo-code-alert-message').removeClass('d-none');
                        }
                        if ($('#promo-code-alert-message').css('display') == 'none') {
                            $('#promo-code-alert-message').css('display', '');
                        }
                        $('#promo-code-alert-message').text($('#promo-code-button').attr('data-promo-code-error-message'));
                        $('#promo-code-alert-message').fadeOut(5000);
                        setTimeout(function () {
                            $('#promo-code-alert-message').addClass('d-none');
                        }, 5000);
                        return false;
                    } else {
                        if ($('#promo-code-alert-message').hasClass('alert-danger')) {
                            $('#promo-code-alert-message').removeClass('alert-danger');
                        }
                        if (!$('#promo-code-alert-message').hasClass('alert-success')) {
                            $('#promo-code-alert-message').addClass('alert-success');
                        }
                        if ($('#promo-code-alert-message').hasClass('d-none')) {
                            $('#promo-code-alert-message').removeClass('d-none');
                        }
                        if ($('#promo-code-alert-message').css('display') == 'none') {
                            $('#promo-code-alert-message').css('display', '');
                        }
                        $('#promo-code-alert-message').text($('#promo-code-button').attr('data-promo-code-success-message'));
                        locationCart.promo_code = promoCode;
                        getProducts(locationId, locationType, null, promoCode);
                        $('#location-promo-code-form-wrapper').fadeOut(5000);
                        $('#location-promo-code-form').addClass('d-none');
                        setTimeout(function () {
                            $('#location-promo-code-form-wrapper').addClass('d-none');
                        }, 5000);
                    }
                }, 1000);
            }

            function checkPromoCode(locationId, promoCode, promoCodeIsValid) {
                $.ajax({
                    type: 'POST',
                    url: "/ajax/event/check_promo_code",
                    data: {
                        location_id: locationId,
                        promo_code: promoCode
                    }
                })
                    .done(function (data) {
                        if (data.promo_code_is_valid !== undefined && data.promo_code_is_valid !== null && data.promo_code_is_valid == true) {
                            promoCodeIsValid(true);
                        } else {
                            promoCodeIsValid(false);
                        }
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    })
            }

            function changeLocationType(locationId, locationType, locationCart) {
                return $.ajax({
                    type: 'POST',
                    url: "/ajax/event/change_location_type",
                    data: {
                        location_id: locationId,
                        location_type: locationType,
                        products: locationCart.products
                    }
                })

                    .done(function (data) {
                        return {
                            are_products_available: data.are_products_available,
                            cart_total_price: data.cart_total_price
                        };
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    })

            }

            function getProducts(locationId, locationType, products = null, promoCode = null) {
                $.ajax({
                    type: 'POST',
                    url: "/ajax/event/get_products",
                    data: {
                        location_id: locationId,
                        location_type: locationType,
                        products: products,
                        promo_code: promoCode
                    }
                })
                    .done(function (data) {
                        if (data.html !== undefined && data.html !== null) {
                            $("#location-products").remove();
                            if ($("#location-type-buttons").length) {
                                $("#location-type-buttons").after(data.html);
                            } else if ($("#location-promo-code-form-wrapper").length) {
                                $("#location-promo-code-form-wrapper").after(data.html);
                            } else {
                                $("#location").after(data.html);
                            }
                        }
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    })
            }

            function emptyCart() {
                locationCart.order_storage_identifier = '';
                locationCart.number_of_products = 0;
                locationCart.amount = 0;
                locationCart.products = [];
                updateCartSummary()
            }

            if ($(".clear-shopping-cart").length) {
                emptyCart();
            }

            $(document).on("click", ".order-button", function (e) {
                locationCart.order_storage_identifier = '';
                locationCart.payment_method = $(this).attr('data-payment-method');
            });

            let order_ajax_lock = false;
            $(document).on("submit", "#order_register_form", function (e) {
                $('div.loader').remove();
                e.preventDefault();

                if (!order_ajax_lock) {
                    $(this).append('<div class="loader"></div>');

                    order_ajax_lock = true;

                    if ($('#pick-up-at-the-bar').length) {
                        locationCart.pick_up_at_the_bar = true;
                    }

                    let newOrderData = {
                        location_id: locationId,
                        location_type: locationCart.location_type,
                        table_number: locationCart.table_number,
                        products: locationCart.products,
                        tips_percent: locationCart.tips_percent,
                        payment_method: locationCart.payment_method,
                        comment: locationCart.comment,
                        name: locationCart.name,
                        phone_number: locationCart.phone_number,
                        pickup_date: locationCart.pickup_date,
                        pickup_time: locationCart.pickup_time,
                        promo_code: locationCart.promo_code,
                        custom_pickup_time: $('#custom_pickup_time').val(),
                        pick_up_at_the_bar: locationCart.pick_up_at_the_bar,
                        order_storage_identifier: locationCart.order_storage_identifier
                    }

                    if (orderStorageIds !== null) {
                        newOrderData.order_storage_ids = orderStorageIds;
                    }

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/order/new",
                        data: newOrderData
                    })
                        .done(function (data) {

                            if (data.payment_redirect_url !== undefined && data.payment_redirect_url !== null) {
                                document.location.href = data.payment_redirect_url;
                            }

                            if (data.confirmation_code !== undefined && data.confirmation_code !== null) {
                                const orderHtml = (data.order_html !== undefined && data.order_html !== null) ? data.order_html : '';
                                if ($('#content').hasClass('is-employee') && (locationCart.payment_method == 'payment_terminal' || locationCart.payment_method == 'payment_cash' || locationCart.payment_method == 'payment_method_coupon' || locationCart.payment_method == 'none')) {
                                    emptyCart();
                                    $('#cart-modal').modal('hide');
                                    $('.confirmation-toast').remove();
                                    $('#content').append('<div class="position-fixed bottom-0 right-0 p-3 confirmation-toast" style="z-index: 1001; right: 0; bottom: 0;">' +
                                        '<div role="alert" aria-live="assertive" aria-atomic="true" class="toast" data-autohide="true" data-delay="2000">' +
                                        '  <div class="toast-header">' +
                                        '    <strong class="mr-auto">Ordre registrert</strong>' +
                                        '    <small></small>' +
                                        '    <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">' +
                                        '      <span aria-hidden="true">&times;</span>' +
                                        '    </button>' +
                                        '  </div>' +
                                        '  <div class="toast-body">' + orderHtml +
                                        '  </div>' +
                                        '</div>' +
                                        '</div>');
                                    $('.toast').toast('show');
                                    setTimeout(function () {
                                        order_ajax_lock = false;
                                        cartQuickPaymentTerminalLock = false;
                                    }, 3000)
                                } else {
                                    document.location.href = "/order/confirmation/" + data.confirmation_code;
                                }
                            }

                        })
                        .fail(function (data) {
                            if (data.responseJSON !== undefined && data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                if (!$('#order-button-wrapper').is(':visible')) {
                                    alert(data.responseJSON.error_message);
                                } else {
                                    prependAlert('#order-button-wrapper', data.responseJSON.error_message, 'danger', null, '#order_register_form')
                                }
                            }
                            order_ajax_lock = false;
                            cartQuickPaymentTerminalLock = false;

                        })
                        .always(function () {
                            locationCart.order_storage_identifier = '';
                            if (!($('#content').hasClass('is-employee') && (locationCart.payment_method == 'payment_terminal' || locationCart.payment_method == 'payment_cash' || locationCart.payment_method == 'payment_method_coupon'))) {
                                order_ajax_lock = false;
                            }
                        });
                }
                return false;

            });

            $(document).on('click', '.shop-employee-mode .shop-product', function () {
                const productContainer = $(this).closest('.shop-product-container');
                $('.add-product', productContainer).click();
            });

            let cartQuickPaymentTerminalLock = false;
            $(document).on('click', '.cart-quick-payment-terminal .order-button', function () {
                if (!cartQuickPaymentTerminalLock) {
                    cartQuickPaymentTerminalLock = true;
                    getCartModal(true);
                }
            });

            $(document).on('click', '#reset-shopping-cart', function () {
                emptyCart();
                return false;
            });


            let isNameRequired = false;
            let isPhoneNumberRequired = false;
            $(document).on('click', '#pick-up-at-the-bar-button', function () {
                if (isNameRequired === false && $('#name').prop('required')) {
                    isNameRequired = true;
                }
                if (isPhoneNumberRequired === false && $('#phone_number').prop('required')) {
                    isPhoneNumberRequired = true;
                }
                if ($(this).hasClass('btn-primary')) {
                    locationCart.pick_up_at_the_bar = true;
                    $(this).removeClass('btn-primary');
                    $(this).addClass('btn-light');
                    $('#table_number').attr('required', false);
                    $('#name').attr('required', false);
                    $('#phone_number').attr('required', false);
                    $('#table_number').val('');
                    $('#name').val('');
                    $('#phone_number').val('');
                    if (!$('.get-delivered-to-table').hasClass('d-none')) {
                        $('.get-delivered-to-table').addClass('d-none');
                    }
                    if ($(this).closest('div').hasClass('col-4')) {
                        $(this).closest('div').removeClass('col-4');
                        $(this).closest('div').addClass('col-8');
                    }
                } else {
                    locationCart.pick_up_at_the_bar = false;
                    $(this).removeClass('btn-light');
                    $(this).addClass('btn-primary');
                    if ($('.get-delivered-to-table').hasClass('d-none')) {
                        $('.get-delivered-to-table').removeClass('d-none');
                    }
                    if ($(this).closest('div').hasClass('col-8')) {
                        $(this).closest('div').removeClass('col-8');
                        $(this).closest('div').addClass('col-4');
                    }
                    $('#table_number').attr('required', true);
                    if (isNameRequired) {
                        $('#name').attr('required', true);
                    }
                    if (isNameRequired) {
                        $('#phone_number').attr('required', true);
                    }

                }
                return false;
            });

            $(document).on('hide.bs.modal', '#cart-modal', function (event) {
                console.log('hide', orderStorageIds);
                if (orderStorageIds !== null) {
                    emptyCart();
                    orderStorageIds = null;
                }
            });

        }

    });